import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import InstagrampostFour from "pages/InstagrampostFour";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    {
      path: "instagrampostfour",
      element: <InstagrampostFour />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
