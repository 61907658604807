import React from "react";
import { Helmet } from "react-helmet";
import { Img, Button } from "../../components";

export default function InstagrampostFourPage() {
  return (
    <>
      <Helmet>
        <title>Yunusov abdulmajid's Application3</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-row justify-between items-start h-[1080px] w-full p-[84px] bg-gradient">
        <div className="flex flex-col items-center justify-start w-[42%] mb-[117px] ml-[41px]">
          <div className="flex flex-col items-center justify-center w-full p-3.5 bg-white-A700 rounded-[50px]">
            <div className="h-[764px] w-full mb-0.5 relative">
              <Img
                src="images/img_new_2_1.png"
                alt="new2one_one"
                className="justify-center h-[764px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute"
              />
              <div className="flex flex-col items-center justify-start h-[21px] w-[21px] bottom-[19%] right-0 left-0 m-auto absolute">
                <Button className="flex items-center justify-center h-[21px] w-[21px] bg-white-A700_66 rounded-[10px]">
                  <Img src="images/img_group_1.svg" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start w-[42%] mt-[91px] mr-[52px]">
          <div className="flex flex-col items-center justify-start w-full p-3.5 bg-white-A700 rounded-[50px]">
            <Img src="images/img_new_1_1.png" alt="new1one_one" className="w-full object-cover" />
          </div>
        </div>
      </div>
    </>
  );
}
